export const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
            enabled: true,
        },
        classes: "shadow-md bg-purple-dark",
        scrollTo: true,
    },
    useModalOverlay: true,
};

